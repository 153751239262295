import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faXmark,
  faChevronDown,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTelegram,
  faDiscord,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';
import LogoSvg from '../assets/Logo.svg';
import { Link } from 'react-router-dom';

const customStyles = {
  backgroundGradient: {
    background:
      'linear-gradient(90.18deg, rgba(11, 12, 13, 0.5) 1.44%, rgba(11, 12, 13, 0.2) 100%)',
    border: '0.94px solid rgba(255, 255, 255, 0.1)',
  },
};

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isCommunityOpen, setIsCommunityOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsCommunityOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='fixed w-full top-0 z-50 flex justify-center'>
      <nav
        style={customStyles.backgroundGradient}
        className={`w-[90%] md:w-[85%] mx-auto transition-all duration-300 backdrop-blur-sm ${
          isScrolled
            ? 'shadow-lg'
            : 'mt-10 rounded-lg md:rounded-full shadow-md'
        }`}
      >
        <div className='px-4 sm:px-6 lg:px-8'>
          <div className='flex items-center h-16'>
            <div className='flex-shrink-0 flex items-center'>
              <Link to='/'>
                <img
                  className='h-8 w-auto'
                  loading='lazy'
                  src={LogoSvg}
                  alt='Logo'
                />
              </Link>
            </div>

            <div className='flex md:hidden ml-auto'>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className='inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white/20'
              >
                <FontAwesomeIcon
                  icon={isOpen ? faXmark : faBars}
                  className='h-6 w-6'
                />
              </button>
            </div>

            <div className='hidden md:flex flex-1 justify-center items-center space-x-8'>
              <a
                href='/#referral'
                className='text-white hover:text-gray-200 transition-colors'
              >
                Referral
              </a>

              <div className='relative' ref={dropdownRef}>
                <button
                  onClick={() => setIsCommunityOpen(!isCommunityOpen)}
                  className='inline-flex items-center text-white hover:text-gray-200 transition-colors'
                >
                  Community
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className='ml-1 h-4 w-4'
                  />
                </button>

                {isCommunityOpen && (
                  <div className='absolute left-1/2 -translate-x-1/2 mt-2 w-48 rounded-lg shadow-xl bg-[rgba(11,12,13,0.95)] border border-white/10'>
                    <Link
                      to='#twitter'
                      className='flex items-center px-4 py-2 text-sm text-white hover:bg-white/5'
                    >
                      <FontAwesomeIcon
                        icon={faX}
                        className='h-5 w-5 mr-2 text-white'
                      />
                      X
                    </Link>
                    <Link
                      to='#github'
                      className='flex items-center px-4 py-2 text-sm text-white hover:bg-white/5'
                    >
                      <FontAwesomeIcon
                        icon={faGithub}
                        className='h-5 w-5 mr-2 text-white'
                      />
                      Github
                    </Link>
                    <Link
                      to='#telegram'
                      className='flex items-center px-4 py-2 text-sm text-white hover:bg-white/5'
                    >
                      <FontAwesomeIcon
                        icon={faTelegram}
                        className='h-5 w-5 mr-2 text-white'
                      />
                      Telegram
                    </Link>
                    <Link
                      to='#discord'
                      className='flex items-center px-4 py-2 text-sm text-white hover:bg-white/5'
                    >
                      <FontAwesomeIcon
                        icon={faDiscord}
                        className='h-5 w-5 mr-2 text-white'
                      />
                      Discord
                    </Link>
                  </div>
                )}
              </div>

              <a
                href='/#faq'
                className='text-white hover:text-gray-200 transition-colors'
              >
                FAQ
              </a>
            </div>

            <div className='md:flex items-center ml-auto'>
              <Link
                to='#telegram'
                className='inline-flex items-center px-4 py-2 text-sm font-medium rounded-full text-white transition-colors'
              >
                <span className='hidden md:inline'>Go to Telegram</span>
                <FontAwesomeIcon icon={faTelegram} className='h-5 w-5 ml-3' />
              </Link>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div
          className={`${isOpen ? 'block' : 'hidden'} md:hidden`}
          style={{ borderTop: '0.94px solid rgba(255, 255, 255, 0.1)' }}
        >
          <div className='px-2 pt-2 pb-3 space-y-1'>
            <a
              href='/#referral'
              className='block px-3 py-2 rounded-md text-base text-white hover:bg-white/5'
            >
              Referral
            </a>

            <div>
              <button
                onClick={() => setIsCommunityOpen(!isCommunityOpen)}
                className='w-full text-left px-3 py-2 rounded-md text-base text-white hover:bg-white/5 flex items-center justify-between'
              >
                Community
                <FontAwesomeIcon icon={faChevronDown} className='ml-2' />
              </button>

              {isCommunityOpen && (
                <div className='pl-6 space-y-1'>
                  <Link
                    to='#twitter'
                    className='flex items-center px-3 py-2 text-sm text-white hover:bg-white/5'
                  >
                    <FontAwesomeIcon
                      icon={faX}
                      className='h-5 w-5 mr-2 text-white'
                    />
                    X
                  </Link>
                  <Link
                    to='#github'
                    className='flex items-center px-3 py-2 text-sm text-white hover:bg-white/5'
                  >
                    <FontAwesomeIcon
                      icon={faGithub}
                      className='h-5 w-5 mr-2 text-white'
                    />
                    Github
                  </Link>
                  <Link
                    to='#telegram'
                    className='flex items-center px-3 py-2 text-sm text-white hover:bg-white/5'
                  >
                    <FontAwesomeIcon
                      icon={faTelegram}
                      className='h-5 w-5 mr-2 text-white'
                    />
                    Telegram
                  </Link>
                  <Link
                    to='#discord'
                    className='flex items-center px-3 py-2 text-sm text-white hover:bg-white/5'
                  >
                    <FontAwesomeIcon
                      icon={faDiscord}
                      className='h-5 w-5 mr-2 text-white'
                    />
                    Discord
                  </Link>
                </div>
              )}
            </div>

            <a
              href='/#faq'
              className='block px-3 py-2 rounded-md text-base text-white hover:bg-white/5'
            >
              FAQ
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
