import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDiscord,
  faMedium,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';
import LogoSvg from '../assets/Logo.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const socialLinks = [
    { icon: faDiscord, label: 'Discord', url: 'https://discord.com' },
    { icon: faMedium, label: 'Medium', url: 'https://medium.com' },
    { icon: faGithub, label: 'GitHub', url: 'https://github.com' },
  ];

  return (
    <footer className='bg-whytaptraderbg text-white'>
      <div className='w-[90%] md:w-[85%]   mx-auto px-4 sm:px-6 lg:px-8 py-12'>
        <div className='grid grid-cols-1 lg:grid-cols-12 gap-8 lg:gap-12'>
          <div className='lg:col-span-4'>
            <a href='/'>
              <img
                src={LogoSvg}
                alt='Logo'
                loading='lazy'
                className='mb-5 w-42 md:w-56 h-auto'
              />
            </a>
            <p className='text-gray-400 text-sm leading-relaxed max-w-md'>
              TapTrader is your ultimate trading companion. Manage your
              portfolio with advanced tools and low-cost execution across
              Ethereum, Solana, and Base.
            </p>
          </div>

          <div className='lg:col-span-8'>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
              {/* Tools Section */}
              <div>
                <h3 className='text-lg font-semibold mb-4 text-teal-200'>
                  Tools and Preferences
                </h3>
                <ul className='space-y-3'>
                  <li>
                    <Link
                      to='/doc'
                      className='text-gray-400 hover:text-teal-200 transition-colors duration-200 text-sm'
                    >
                      Documentation
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/gitbook'
                      className='text-gray-400 hover:text-teal-200 transition-colors duration-200 text-sm'
                    >
                      Gitbook
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className='text-lg font-semibold mb-4 text-teal-200'>
                  Community
                </h3>
                <ul className='space-y-3'>
                  {socialLinks.map((link) => (
                    <li key={link.label}>
                      <Link
                        to={link.url}
                        className='flex items-center group text-gray-400 hover:text-teal-200 transition-colors duration-200 text-sm'
                      >
                        <FontAwesomeIcon
                          icon={link.icon}
                          className='w-4 h-4 mr-3 text-teal-200 group-hover:text-teal-200'
                        />
                        {link.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <h3 className='text-lg font-semibold mb-4 text-teal-200'>
                  Career
                </h3>
                <ul className='space-y-3'>
                  <li>
                    <Link
                      to='/hiring'
                      className='text-gray-400 hover:text-teal-200 transition-colors duration-200 text-sm'
                    >
                      We Are Hiring
                    </Link>
                  </li>
                  <li className='pt-2'>
                    <button className='w-full sm:w-auto bg-teal-200 text-black text-sm font-semibold px-6 py-2 rounded-full hover:bg-teal-200 transition-colors duration-200'>
                      Join Us
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-12 pt-8 border-t border-gray-800'>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 items-center'>
            <div className='text-gray-400 text-sm'>
              © {currentYear} TapTrader. All rights reserved
            </div>
            <div className='lg:col-span-2 flex justify-center space-x-8'>
              <a
                href='/terms-of-service'
                className='text-gray-400 hover:text-teal-200 transition-colors duration-200 text-sm'
              >
                Terms of Service
              </a>
              <a
                href='/privacy-policy'
                className='text-gray-400 hover:text-teal-200 transition-colors duration-200 text-sm'
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
