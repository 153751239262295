import React, { useState, useEffect, useMemo } from 'react';
import base from '../assets/base.png';
import ton from '../assets/ton.png';
import eth from '../assets/eth1.png';
import solana from '../assets/solana.png';

const ChainSupported = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [desktopIndex, setDesktopIndex] = useState(0);
  
  const chains = useMemo(() => [
    { name: 'Solana', logo: solana },
    { name: 'Ethereum', logo: eth },
    { name: 'Ton', logo: ton },
    { name: 'Base', logo: base },
    { name: 'Solana', logo: solana },
    { name: 'Ethereum', logo: eth },
    { name: 'Ton', logo: ton },
    { name: 'Base', logo: base },
  ], []);

  const chainsLength = chains.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === chainsLength - 2 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [chainsLength]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDesktopIndex((prevIndex) => 
        prevIndex === chainsLength - 4 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [chainsLength]);

  return (
    <section className="bg-whytaptraderbg py-10 md:py-10">
      <div className="w-[90%] md:w-[85%] mx-auto">
        <div className="block md:hidden">
          <div className="overflow-hidden">
          <h2 className='text-white text-xl mb-5 text-center md:text-3xl lg:text-[40px] font-extrabold tracking-tight leading-tight'>
            Chain Supported
          </h2>
            <div
              className="flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 50}%)` }}
            >
              {chains.map((chain, index) => (
                <div
                  key={index}
                  className="min-w-[50%] flex items-center justify-left space-x-4 px-2"
                >
                  <img src={chain.logo} alt={chain.name} className="h-14 w-14" />
                  <span className="text-white text-lg capitalize">
                    {chain.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div> 
        <div className="hidden md:block">
          <div className="overflow-hidden">
          <h2 className='text-white text-xl md:text-3xl lg:text-4xl text-center mb-10 font-extrabold tracking-tight leading-tight'>
            Chain Supported
          </h2>
            <div
              className="flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${desktopIndex * 25}%)` }}
            >
              {chains.map((chain, index) => (
                <div
                  key={index}
                  className="min-w-[25%] flex items-center justify-left space-x-4 px-4 transition-transform duration-300 hover:scale-105"
                >
                  <img src={chain.logo} alt={chain.name} className="h-14 w-14" />
                  <span className="text-white text-xl lg:text-3xl capitalize">
                    {chain.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChainSupported;