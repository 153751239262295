import React from 'react';
import '../styles/privacy.css';
import Header from '../component/Header';

const SectionTitle = ({ children }) => (
  <h2 className='text-2xl md:text-3xl font-bold text-white mb-6 flex items-center'>
    <div className='w-1.5 h-8 bg-teal-400 mr-4 rounded-full'></div>
    {children}
  </h2>
);

const ListItem = ({ children }) => (
  <li className='flex items-start space-x-3 mb-2'>
    <div className='w-2 h-2 bg-teal-400 rounded-full mt-2'></div>
    <span className='text-gray-300'>{children}</span>
  </li>
);

function TermsOfService() {
  return (
    <div className='min-h-screen bg-gradient-to-b from-black to-gray-900'>
      <div className='blur-wrapperpri'>
        <Header />
        <div className='w-[90%] md:w-[85%] blur-bgpri mx-auto mt-28 px-4 sm:px-6 lg:px-8 py-5 md:py-10'>
          {/* Header Section */}
          <div className='text-center mb-1 md:mb-14 lg:mb-16'>
            <h1 className='text-3xl md:text-3xl  lg:text-[40px] font-bold text-white mb-6 bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-blue-500'>
              Terms of Service
            </h1>
            <p className='text-gray-400 text-sm md:text-base'>
              Last Updated: 11/08/2024
            </p>
          </div>

          {/* Introduction */}
          <div className='bg-gray-900/50 backdrop-blur-sm rounded-2xl p-8 mb-8 shadow-xl border border-gray-800'>
            <h2 className='text-2xl  font-bold text-white mb-4'>
              Welcome to TapTrader!
            </h2>
            <p className='text-gray-300 leading-relaxed'>
              By accessing or using TapTrader (the "Service"), you agree to
              abide by these Terms of Service ("Terms"). Please read them
              carefully. If you do not agree with any part of these Terms,
              please do not use our Service.
            </p>
          </div>

          {/* Main Content */}
          <div className='space-y-12'>
            {/* Acceptance of Terms */}
            <section>
              <SectionTitle>1. Acceptance of Terms</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 leading-relaxed'>
                  By creating an account or using the Service, you confirm that
                  you are at least 18 years old and capable of entering into
                  legally binding agreements. Your continued use of TapTrader
                  constitutes your acceptance of these Terms, which may be
                  updated periodically. It is your responsibility to review the
                  Terms regularly.
                </p>
              </div>
            </section>

            {/* Use of Service */}
            <section>
              <SectionTitle>2. Use of the Service</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 mb-4'>
                  TapTrader grants you a limited, non-exclusive,
                  non-transferable, and revocable license to use the Service for
                  personal, non-commercial purposes, as permitted by these
                  Terms. You agree not to:
                </p>
                <ul className='space-y-2'>
                  <ListItem>
                    Use the Service for any illegal or unauthorized purposes.
                  </ListItem>
                  <ListItem>
                    Attempt to harm or interfere with the Service, our users, or
                    our network.
                  </ListItem>
                  <ListItem>
                    Copy, distribute, or disclose any part of the Service in any
                    medium, except as explicitly allowed.
                  </ListItem>
                </ul>
              </div>
            </section>

            {/* Account Registration */}
            <section>
              <SectionTitle>3. Account Registration and Security</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 leading-relaxed'>
                  To use certain features, you may be required to register and
                  create an account. You are responsible for maintaining the
                  confidentiality of your account and password and for all
                  activities under your account. TapTrader reserves the right to
                  suspend or terminate your account if we suspect any
                  unauthorized or inappropriate use.
                </p>
              </div>
            </section>

            {/* Fees and Payments */}
            <section>
              <SectionTitle>4. Fees and Payments</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 leading-relaxed'>
                  If you choose to access premium features or make transactions
                  on TapTrader, you may be required to pay fees. These fees,
                  which will be disclosed to you before purchase, are
                  non-refundable unless stated otherwise. TapTrader reserves the
                  right to change pricing or introduce new fees, which will be
                  communicated before taking effect.
                </p>
              </div>
            </section>

            {/* User Content */}
            <section>
              <SectionTitle>5. User Content and Conduct</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 leading-relaxed'>
                  You are responsible for any content you post or share on
                  TapTrader. By posting content, you grant us a worldwide,
                  non-exclusive, royalty-free license to use, reproduce, and
                  display your content within the Service. You agree not to post
                  content that is unlawful, harmful, or violates others' rights.
                </p>
              </div>
            </section>

            {/* Intellectual Property */}
            <section>
              <SectionTitle>6. Intellectual Property</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 leading-relaxed'>
                  All materials on TapTrader, including but not limited to text,
                  logos, graphics, and software, are the property of TapTrader
                  or our licensors and are protected by intellectual property
                  laws. You may not use our trademarks or copyrighted materials
                  without written permission.
                </p>
              </div>
            </section>

            {/* Limitation of Liability */}
            <section>
              <SectionTitle>7. Limitation of Liability</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 leading-relaxed'>
                  TapTrader and its affiliates are not liable for any direct,
                  indirect, incidental, special, or consequential damages
                  arising out of or in connection with your use of the Service,
                  including but not limited to lost profits, data, or other
                  losses.
                </p>
              </div>
            </section>

            {/* Indemnification */}
            <section>
              <SectionTitle>8. Indemnification</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 leading-relaxed'>
                  You agree to defend, indemnify, and hold harmless TapTrader
                  and its affiliates from any claims, liabilities, damages,
                  losses, and expenses arising from your use of the Service or
                  violation of these Terms.
                </p>
              </div>
            </section>

            {/* Termination */}
            <section>
              <SectionTitle>9. Termination</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 leading-relaxed'>
                  We reserve the right to suspend or terminate your access to
                  TapTrader at our sole discretion, without notice, for conduct
                  that we believe violates these Terms or is harmful to our
                  users, us, or third parties.
                </p>
              </div>
            </section>

            {/* Governing Law */}
            <section>
              <SectionTitle>10. Governing Law and Disputes</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 leading-relaxed'>
                  These Terms are governed by and construed in accordance with
                  the laws of [your country/state], without regard to its
                  conflict of laws principles. Any disputes arising out of or in
                  connection with these Terms shall be resolved exclusively in
                  the courts of [your country/state].
                </p>
              </div>
            </section>

            {/* Changes to Terms */}
            <section>
              <SectionTitle>11. Changes to Terms</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 leading-relaxed'>
                  TapTrader may update these Terms from time to time. Changes
                  will be effective when posted. Your continued use of the
                  Service after changes are posted constitutes your acceptance
                  of the new Terms.
                </p>
              </div>
            </section>

            {/* Contact Section */}
            <section>
              <SectionTitle>12. Contact Us</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 text-center'>
                <p className='text-gray-300 mb-4'>
                  For questions about these Terms, please contact us at:
                </p>
                <a
                  href='mailto:support@taptrader.io'
                  className='inline-flex items-center px-6 py-3 bg-teal-500 text-black rounded-full font-semibold hover:bg-teal-400 transition-colors duration-200'
                >
                  support@taptrader.io
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;
