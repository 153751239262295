import React from 'react';
import Header from '../component/Header';
import '../styles/privacy.css';

const SectionTitle = ({ children }) => (
  <h2 className='text-2xl md:text-3xl font-bold text-white mb-6 flex items-center'>
    <div className='w-1.5 h-8 bg-teal-400 mr-4 rounded-full'></div>
    {children}
  </h2>
);

const ListItem = ({ title, children }) => (
  <li className='flex items-start space-x-3 mb-4'>
    <div className='w-2 h-2 bg-teal-400 rounded-full mt-2'></div>
    <div>
      {title && <span className='font-semibold text-teal-300'>{title}</span>}
      <span className='text-gray-300'> {children}</span>
    </div>
  </li>
);

function PrivacyPolicy() {
  return (
    <div className='min-h-screen bg-gradient-to-b from-black to-gray-900'>
      <div className='blur-wrapperpri'>
        <Header />
        <div className='w-[90%] md:w-[85%] blur-bgpri mt-28 mx-auto px-4 sm:px-6 lg:px-8 py-5 md:py-10'>
          <div className='text-center mb-1 md:mb-14 lg:mb-16'>
            <h1 className='text-3xl md:text-3xl  lg:text-[40px]  font-bold text-white mb-6 bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-blue-500'>
              Privacy Policy
            </h1>
            <p className='text-gray-400 text-sm md:text-base'>
              Last Updated: 10/08/2024
            </p>
          </div>

          <div className='bg-gray-900/50 backdrop-blur-sm rounded-2xl p-8 mb-8 shadow-xl border border-gray-800'>
            <p className='text-gray-300 leading-relaxed'>
              TapTrader ("we," "us," or "our") is committed to protecting your
              privacy. This Privacy Policy explains how we collect, use, share,
              and protect your information when you use our services. By using
              TapTrader, you agree to the terms outlined in this policy.
            </p>
          </div>

          <div className='space-y-12'>
            <section>
              <SectionTitle>1. Information We Collect</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 space-y-4'>
                <p className='text-gray-300'>
                  We collect information directly from users, from third
                  parties, and automatically when you use TapTrader. This may
                  include:
                </p>
                <ul className='space-y-2'>
                  <ListItem title='Account Information:'>
                    Data such as name, email, and other contact information when
                    you register.
                  </ListItem>
                  <ListItem title='Financial Data:'>
                    Payment information for transactions processed through
                    TapTrader.
                  </ListItem>
                  <ListItem title='Device and Usage Data:'>
                    Information on your device, IP address, browser type, and
                    usage patterns.
                  </ListItem>
                  <ListItem title='Location Data:'>
                    With your permission, we may collect precise location data
                    to improve your user experience.
                  </ListItem>
                </ul>
              </div>
            </section>

            {/* Information Usage */}
            <section>
              <SectionTitle>2. How We Use Your Information</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 mb-4'>
                  We use the collected information to:
                </p>
                <ul className='space-y-2'>
                  <ListItem>
                    Provide, maintain, and improve TapTrader services.
                  </ListItem>
                  <ListItem>
                    Personalize your experience and show relevant content.
                  </ListItem>
                  <ListItem>
                    Communicate with you, including updates, security alerts,
                    and support messages.
                  </ListItem>
                  <ListItem>
                    Conduct analytics and research to enhance our services.
                  </ListItem>
                  <ListItem>Ensure compliance with legal obligations.</ListItem>
                </ul>
              </div>
            </section>

            {/* Information Sharing */}
            <section>
              <SectionTitle>3. Sharing of Information</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 mb-4'>
                  We may share information with third parties, including:
                </p>
                <ul className='space-y-2'>
                  <ListItem title='Service Providers:'>
                    Third-party vendors that provide services like hosting,
                    analytics, and customer support.
                  </ListItem>
                  <ListItem title='Business Transfers:'>
                    In cases of mergers, acquisitions, or asset sales, user data
                    may be transferred.
                  </ListItem>
                  <ListItem title='Legal Requirements:'>
                    To comply with applicable laws or respond to legal requests.
                  </ListItem>
                </ul>
                <p className='text-gray-300 mt-4 font-medium'>
                  We do not sell personal information to third parties.
                </p>
              </div>
            </section>

            {/* User Rights and Choices */}
            <section>
              <SectionTitle>4. User Rights and Choices</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300 mb-4'>
                  Depending on your jurisdiction, you may have rights to:
                </p>
                <ul className='space-y-2'>
                  <ListItem>
                    Access, correct, or delete personal information we hold
                    about you.
                  </ListItem>
                  <ListItem>
                    Object to or restrict certain processing activities.
                  </ListItem>
                  <ListItem>Withdraw consent where applicable.</ListItem>
                </ul>
                <p className='text-gray-300 mt-4'>
                  Please contact us for more information or to exercise these
                  rights.
                </p>
              </div>
            </section>

            {/* Security */}
            <section>
              <SectionTitle>5. Security of Your Information</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300'>
                  We employ a range of security measures, including encryption
                  and access controls, to protect user data. While we strive to
                  secure your information, no method of transmission or storage
                  is 100% secure.
                </p>
              </div>
            </section>

            {/* International Data Transfers */}
            <section>
              <SectionTitle>6. International Data Transfers</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300'>
                  If you access TapTrader outside your country, your information
                  may be transferred internationally to servers in the United
                  States or other countries. We take appropriate safeguards to
                  protect your information.
                </p>
              </div>
            </section>

            {/* Changes to Policy */}
            <section>
              <SectionTitle>7. Changes to this Privacy Policy</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6'>
                <p className='text-gray-300'>
                  We may update this Privacy Policy periodically. Changes will
                  be posted on this page with an updated date. We encourage you
                  to review this policy regularly to stay informed.
                </p>
              </div>
            </section>

            {/* Contact Section */}
            <section>
              <SectionTitle>8. Contact Us</SectionTitle>
              <div className='bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 text-center'>
                <p className='text-gray-300 mb-4'>
                  For questions about this Privacy Policy, please contact us at:
                </p>
                <a
                  href='mailto:support@taptrader.io'
                  className='inline-flex items-center px-6 py-3 bg-teal-500 text-black rounded-full font-semibold hover:bg-teal-400 transition-colors duration-200'
                >
                  support@taptrader.io
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
