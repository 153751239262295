import React from 'react';
import HighSpeed from '../assets/1.svg';
import MEVImage from '../assets/2.svg';
import MultiChain from '../assets/3.svg';
import UserFriendly from '../assets/4.svg';
import '../styles/HowItWorks.css';

function HowItWorks() {
  const steps = [
    {
      description:
        'Your TapTrader banner or link is displayed on your website.',
      imageUrl: HighSpeed,
      step: '01',
    },
    {
      description: 'Visitors click on the banner or link.',
      imageUrl: MEVImage,
      step: '02',
    },
    {
      description:
        'Our system tracks the unique URL associated with your banner or link.',
      imageUrl: MultiChain,
      step: '03',
    },
    {
      description:
        'Visitors who click through make trades, and you benefit from their activity.',
      imageUrl: UserFriendly,
      step: '04',
    },
  ];

  return (
    <section className='bg-whytaptraderbg py-10 md:py-16'>
      <div className='blur-wrapperw'>
        <div className='w-[90%] md:w-[85%] blur-bg mx-auto'>
          <div className='text-center space-y-4 mb-12'>
            <h2 className='text-white text-3xl md:text-3xl lg:text-[40px] font-extrabold tracking-tight leading-tight'>
              How It Works
            </h2>
            <p className='text-gray-400 text-base md:text-lg max-w-2xl mx-auto leading-relaxed'>
              Follow these simple steps to start earning with TapTrader
            </p>
          </div>

          <div className='grid grid-cols-1  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'>
            {steps.map((item, index) => (
              <div
                className='rounded-xl border-tapTraderBg bg-whytaptraderbg z-10 border p-6 md:p-8 text-center relative group transition-all duration-300'
                key={index}
              >
                <span className='absolute top-4 left-4 text-xs font-medium text-gray-500 tracking-widest'>
                  STEP {item.step}
                </span>

                <div className='flex justify-center items-center mb-6'>
                  <img
                    src={item.imageUrl}
                    alt={`Step ${index + 1}`}
                    className='object-center w-16 h-16 transition-transform duration-300 group-hover:scale-110'
                  />
                </div>

                <p className='text-gray-300 text-base md:text-base leading-relaxed tracking-wide'>
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
