import React from 'react';
import HighSpeed from '../assets/High-Speed-Low-Cost.svg';
import MEVImage from '../assets/MEV-Protection.svg';
import MultiChain from '../assets/Multichain-Support.svg';
import UserFriendly from '../assets/User-Friendly-Interface.svg';

function WhyTrader() {
  const data = [
    {
      title: 'User Friendly Interface',
      description:
        "TapTrader's intuitive Telegram interface: easy, efficient, and accessible for all traders.",
      imageUrl: UserFriendly,
    },
    {
      title: 'High Speed & Low Cost',
      description:
        'Stay ahead with Sniper Tools, capturing top entry points and token launches with low fees.',
      imageUrl: HighSpeed,
    },
    {
      title: 'Multichain Support',
      description:
        'Effortlessly trade Ethereum, Solana, and Base. Diversify and maximize liquidity with one powerful bot.',
      imageUrl: MultiChain,
    },
    {
      title: 'MEV Protection',
      description:
        'Secure your trades from MEV bots with private transactions, blocking frontrunning and sandwich attacks.',
      imageUrl: MEVImage,
    },
  ];

  return (
    <section className='bg-whytaptraderbg py-5 Md:py-6 lg:py-8'>
      <div className='w-[90%] md:w-[85%] mx-auto'>
        <div className='space-y-5'>
          <h2 className=' text-tapTraderBlue  text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-bold tracking-tight'>
            Why TapTrader?
          </h2>
          <p className='text-base md:text-base text-white leading-relaxed tracking-wide '>
            You can now view tap-trader-portfolio in the browser.You can now
            view tap-trader-portfolio in the browser
          </p>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 my-8 gap-6'>
          {data.map((item, index) => (
            <div
              className=' rounded-xl p-6 border-t border-r border-tapTraderBg '
              key={index}
            >
              <div className='space-y-4'>
                <h3 className='text-white text-xl md:text-xl  tracking-tight leading-snug'>
                  {item.title}
                </h3>
                <p className='text-gray-400 text-sm md:text-base leading-relaxed tracking-wide'>
                  {item.description}
                </p>
                <img
                  src={item.imageUrl}
                  loading='lazy'
                  alt={item.title}
                  className='mt-4 w-64 h-52'
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhyTrader;
