import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from './page/Index';
import TermOfServices from './page/TermOfServices';
import Footer from './component/Footer';
import PrivacyPolicy from './page/PrivacyPolicy';
import NotFound from './page/NotFound';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Index />} />

        <Route path='/terms-of-service' element={<TermOfServices />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
