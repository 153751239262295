import React from 'react';
import phone from '../assets/phoneweb.webp';
import Frame from '../assets/Frame.png';
import barrow from '../assets/barrow.png';
import t from '../assets/tarrow.png';
import '../styles/PrivateManagementKey.css';

function PrivateManagementKey() {
  return (
    <section className='bg-whytaptraderbg py-10 md:py-16'>
      <div className='w-[90%] md:w-[85%]  mx-auto'>
        <header className=' mb-12 md:mb-24 lg:mb-12'>
          <h2 className='text-white text-3xl md:text-3xl   lg:text-[40px] font-extrabold tracking-tight leading-tight'>
            Private Key Management Create & Export
          </h2>
          <p className='text-gray-400 text-base md:text-lg leading-relaxed mt-4 '>
            Securely export and import your wallet private keys to manage your
            trading portfolio across different platforms.
          </p>
          <ul className='text-gray-800 text-lg  list-disc pl-6 '>
            <li className='text-base text-gray-400 leading-relaxed'>
              Instant Notifications
            </li>
            <li className='text-base text-gray-400 leading-relaxed'>
              Token Discovery
            </li>
            <li className='text-base text-gray-400 leading-relaxed'>
              Fail Guard Lucrative
            </li>
            <li className='text-base text-gray-400 leading-relaxed'>
              Referral Rewards
            </li>
            <li className='text-base text-gray-400 leading-relaxed'>
              Start Trading
            </li>
          </ul>
          <p className='text-gray-400 text-base leading-relaxed '>
            Start trading in no time with our easy-to-use interface and clear
            instructions.
          </p>
        </header>

        <div className='flex blur-bgp flex-col items-center md:flex-row md:justify-between '>
          <div className='flex relative flex-col items-center md:items-start md:w-1/3'>
            <div className='absolute right-0 -top-20 mb-4 md:mb-6 hidden md:block'>
              <img src={Frame} alt='' className=' h-auto' />
            </div>
            <div className='bg-black border border-gray-700 rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 w-full md:w-full'>
              <h3 className='text-white text-xl font-bold mb-3'>Who We Are</h3>
              <p className='text-gray-400 text-sm leading-relaxed'>
                At TapTrader, we empower traders with cutting-edge tools that
                simplify and enhance the trading experience across Ethereum,
                Solana, and Base networks. As the fastest Telegram-based trading
                bot, TapTrader offers instant trade execution and robust
                portfolio management. Whether you're a newcomer or a seasoned
                trader, our platform is designed to help you navigate the
                complexities of trading with ease.
              </p>
            </div>
          </div>

          <div className='flex flex-col phone___image__container z-10 items-center my-8 md:my-0'>
            <img src={phone} alt='phone' className='phone___image' />
          </div>

          <div className='relative flex flex-col md:-mt-52 items-center md:items-start md:w-1/3'>
            <div className='bg-black border border-gray-700 rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 w-full md:w-full mt-12'>
              <h3 className='text-white text-xl font-bold  mb-3'>Wealth</h3>
              <p className='text-gray-400 text-sm leading-relaxed'>
                Our wealth management tools allow users to make well-informed
                trading decisions with enhanced security. Join a growing
                community that prioritizes smart financial growth and secure
                investment management.
              </p>
            </div>

            <img
              src={barrow}
              alt=''
              className='absolute left-0 -bottom-16 w-auto h-auto hidden md:block'
            />
          </div>
        </div>

        <div className='flex items-center justify-center md:justify-center md:space-x-4 mt-12'>
          <img src={t} alt='' className='w-30 h-30 hidden md:block' />

          <div className='bg-black border border-gray-700 rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 w-full md:w-4/5 lg:w-3/5 xl:w-2/5 mx-auto'>
            <h3 className='text-white text-xl font-bold  mb-3'>True Riches</h3>
            <p className='text-gray-400 text-sm leading-relaxed mt-2'>
              True riches come from knowledge, speed, and security. With our
              high-speed sniping tools, you can capture the most profitable
              trades at the lowest market fees. TapTrader’s secure and accurate
              portfolio management ensures that your investments are protected
              while maximizing your potential for success.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivateManagementKey;
