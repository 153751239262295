import React from 'react';
import { ArrowRight } from 'lucide-react';
import JoinUsWebBg from '../assets/JoinusBg.webp';
import JoinUsMobileBg from '../assets/JoinusMobileBg.webp';

const JoinTapTrader = () => {
  return (
    <section className='bg-black px-2 md:px-4 relative' >
      <div className='' />

      <div
        className=' w-[90%] md:w-[85%]  bg-cover md:bg-cover bg-no-repeat bg-center   text-center rounded-2xl mx-auto  p-6 md:p-10 lg:p-12 shadow-xl backdrop-blur-sm'
        style={{
          backgroundImage: `url(${
            window.innerWidth < 768 ? JoinUsMobileBg : JoinUsWebBg
          })`,
        }}
      >
        <h1 className='text-[30px] md:text-4xl lg:text-[40px]  font-bold capitalize tracking-tight text-gray-900'>
          Join TapTrader Today
        </h1>

        <div className='space-y-6 mt-8'>
          <p className='text-gray-800 text-base md:text-lg lg:text-xl leading-relaxed font-medium max-w-2xl mx-auto'>
            Get started with <span className='font-bold'>$10K</span> free volume
          </p>

          <button className='group relative inline-flex items-center gap-2 py-3 px-8 text-sm font-semibold bg-black rounded-full text-teal-300 hover:text-tapTraderBlue transition-all duration-300 hover:shadow-lg hover:shadow-teal-300/20 focus:outline-none focus:ring-2 focus:ring-teal-300 focus:ring-offset-2 focus:ring-offset-[rgba(147,252,228,1)]'>
            Start Trading
            <ArrowRight className='w-4 h-4 transition-transform duration-300 group-hover:translate-x-1' />
          </button>
        </div>

        {/* Trust indicators */}
        <div className='mt-8 flex justify-center gap-8 text-gray-700 text-sm'>
          <div className='flex items-center'>
            <svg
              className='w-4 h-4 mr-2'
              fill='currentColor'
              viewBox='0 0 20 20'
            >
              <path
                fillRule='evenodd'
                d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                clipRule='evenodd'
              />
            </svg>
            <span>Secure Platform</span>
          </div>
          <div className='flex items-center'>
            <svg
              className='w-4 h-4 mr-2'
              fill='currentColor'
              viewBox='0 0 20 20'
            >
              <path d='M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z' />
            </svg>
            <span>10K+ Traders</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinTapTrader;
