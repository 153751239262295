import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import '../styles/faqs.css';

function Faqs() {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const faqData = [
    {
      title: 'What is TapTrader?',
      description:
        "You can easily purchase tickets directly on our website or through the Roc's Yard mobile app. Simply find the event you want, select your ticket type, and complete your payment securely online.",
    },
    {
      title: 'How does TapTrader work?',
      description:
        "You can easily purchase tickets directly on our website or through the Roc's Yard mobile app. Simply find the event you want, select your ticket type, and complete your payment securely online.",
    },
    {
      title: 'Is TapTrader secure?',
      description:
        'Yes, TapTrader ensures high levels of security by using state-of-the-art encryption methods to protect user data and funds.',
    },
    {
      title: 'Can I trade on multiple networks?',
      description:
        'Yes, TapTrader supports trading on multiple blockchains, including Ethereum, Solana, and Base, providing a versatile trading experience.',
    },
    {
      title: 'Can I trade on multiple networks?',
      description:
        'Yes, TapTrader supports trading on multiple blockchains, including Ethereum, Solana, and Base, providing a versatile trading experience.',
    },
    {
      title: 'Can I trade on multiple networks?',
      description:
        'Yes, TapTrader supports trading on multiple blockchains, including Ethereum, Solana, and Base, providing a versatile trading experience.',
    },
    {
      title: 'Can I trade on multiple networks?',
      description:
        'Yes, TapTrader supports trading on multiple blockchains, including Ethereum, Solana, and Base, providing a versatile trading experience.',
    },
  ];

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section className='bg-whytaptraderbg py-10 md:py-16 px-4' id="faq">
      <div className=''>
        <div className='w-[90%] md:w-[85%] mx-auto blur-bgf'>
          <p className='text-base md:text-xl tracking-[0.2em] text-center text-tapTraderBlue  mb-3 uppercase'>
            Frequently Asked Questions
          </p>
          <h1 className='text-3xl md:text-3xl lg:text-[40px]  text-center text-white font-extrabold mb-12 leading-tight'>
            We're sure you have some
            <span className='md:block md:mt-2'>questions...</span>
          </h1>

          <div className='space-y-5'>
            {faqData.map((faq, index) => (
              <div
                key={index}
                className={`border border-[#4A4A4A] rounded-xl p-6 cursor-pointer bg-[#1A1A1A] bg-opacity-20 transition-all duration-300 hover:border-teal-300/30 ${
                  expandedIndex === index ? 'border-teal-300/30' : ''
                }`}
              >
                <div
                  className='flex justify-between items-center  cursor-pointer'
                  onClick={() => toggleExpand(index)}
                  tabIndex={0}
                  onKeyDown={(e) => e.key === 'Enter' && toggleExpand(index)}
                >
                  <h3 className='text-base md:text-xl text-white font-medium leading-tight'>
                    {faq.title}
                  </h3>
                  <FontAwesomeIcon
                    icon={expandedIndex === index ? faMinus : faPlus}
                    className={`text-lg flex-shrink-0 transition-colors duration-300 ${
                      expandedIndex === index ? 'text-teal-300' : 'text-white'
                    }`}
                  />
                </div>
                {expandedIndex === index && (
                  <p className='mt-4 text-gray-400 text-sm md:text-lg leading-relaxed'>
                    {faq.description}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
