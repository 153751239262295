// import React from 'react';
 import '../styles/RoadMap.css';

// const RoadMap = () => {
//   const milestones = [
//     {
//       date: 'Q4 2024',
//       events: [
//         'Migration to TON, The Open Network',
//         'Telegram Mini App Home',
//         'Polkadot Integration',
//         'User Rewards Kickoff',
//       ],
//     },
//     {
//       date: 'Q1 2025',
//       events: [
//         'Tap Trader Token and TapDAO Launch',
//         'AI Token Analysis / Autotrade BETA',
//         'Advanced Copy Trading',
//       ],
//     },
//     {
//       date: 'Q2 2025',
//       events: [
//         'Tap Trader Token and TapDAO Launch',
//         'AI Token Analysis / Autotrade BETA',
//         'Advanced Copy Trading',
//       ],
//     },
//     {
//       date: 'Q3 2025',
//       events: [
//         'Chain Agnostic Single Interface Launch',
//         'Cross Chain Atomic Swaps',
//         'Cross Chain Order Routing',
//       ],
//     },
//   ];

//   return (
//     <section className='bg-whytaptraderbg py-10 md:py-16'>
//       <div className='w-[90%] md:w-[90%] py-6 px-5 '>
//         <div className='w-[95%] md:w-[85%]  blur-bg1 mx-auto'>
//           <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols1 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
//             <div className='col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1'>
//               <p className='text-[32px] md:text[35px]  lg:text[40px]  text-white'>
//                 TAPTRADER
//               </p>
//             </div>
//             <div className=' mt-1 sm:mt-0'>
//               <p className='text-[32px] md:text[35px]  lg:text[40px]     text-white'>
//                 ROADMAP
//               </p>
//             </div>
//             <div className=''>
//               {milestones.map((milestone, idx) => (
//                 <div key={idx} className='rounded-lg mb-8'>
//                   <p className='text-tapTraderBlue text-[32px] md:text[35px]  lg:text[40px]  rounded-full shadow-lg mb-4'>
//                     {milestone.date}
//                   </p>
//                   <ul className='text-gray-800 text-lg space-y-3 list-disc    '>
//                     {milestone.events.map((event, eventIdx) => (
//                       <li key={eventIdx} className='text-xl text-white'>
//                         {event}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default RoadMap;



import React from 'react';
import { motion } from 'framer-motion';
import { Circle } from 'lucide-react';

const RoadMap = () => {
  const milestones = [
    {
      date: 'Q4 2024',
      title: 'Network Expansion',
      events: [
        'Migration to TON, The Open Network',
        'Telegram Mini App Home',
        'Polkadot Integration',
        'User Rewards Kickoff',
      ],
    },
    {
      date: 'Q1 2025',
      title: 'Platform Evolution',
      events: [
        'Tap Trader Token and TapDAO Launch',
        'AI Token Analysis / Autotrade BETA',
        'Advanced Copy Trading',
      ],
    },
    {
      date: 'Q2 2025',
      title: 'Innovation Phase',
      events: [
        'Tap Trader Token and TapDAO Launch',
        'AI Token Analysis / Autotrade BETA',
        'Advanced Copy Trading',
      ],
    },
    {
      date: 'Q3 2025',
      title: 'Cross-Chain Integration',
      events: [
        'Chain Agnostic Single Interface Launch',
        'Cross Chain Atomic Swaps',
        'Cross Chain Order Routing',
      ],
    },
  ];

  return (
    <section className="min-h-screen bg-gradient-to-b from-black to-gray-900 py-20">
      <div className="w-[90%] md:w-[85%]   blur-bg1 mx-auto px-4">
        {/* Header */}
        <div className="mb-16 text-center">
          <h2 className="text-3xl md:text-[40px] font-bold text-white mb-4">
            <span className="bg-gradient-to-r from-tapTraderBlue to-cyan-400 bg-clip-text text-transparent">
              TAPTRADER
            </span>{' '}
            ROADMAP
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Our strategic journey towards building the future of decentralized trading
          </p>
        </div>

        {/* Timeline */}
        <div className="relative">
          {milestones.map((milestone, idx) => (
            <motion.div
              key={idx}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: idx * 0.2 }}
              className="mb-16 relative"
            >
              <div className="flex flex-col md:flex-row items-start md:items-center gap-8">
                {/* Date Circle */}
                <div className="flex-shrink-0 w-40">
                  <div className="bg-gradient-to-r from-tapTraderBlue to-cyan-400 p-[2px] rounded-full">
                    <div className="bg-gray-900 rounded-full p-4">
                      <p className="text-xl font-bold text-center text-white">
                        {milestone.date}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Connecting Line */}
                {/* {idx !== milestones.length - 1 && (
                  <div className="absolute left-20 top-24 bottom-0 w-[2px] bg-gradient-to-b from-blue-500 to-cyan-400 hidden md:block" />
                )} */}

                {/* Content Card */}
                <div className="flex-grow">
                  <div className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl p-6 shadow-xl hover:shadow-2xl transition-shadow duration-300">
                    <h3 className="text-2xl font-bold text-white mb-4">{milestone.title}</h3>
                    <ul className="space-y-4">
                      {milestone.events.map((event, eventIdx) => (
                        <motion.li
                          key={eventIdx}
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.3, delay: eventIdx * 0.1 }}
                          className="flex items-start gap-3"
                        >
                          <Circle className="w-4 h-4 mt-1 text-tapTraderBlue flex-shrink-0" />
                          <span className="text-gray-300 text-lg">{event}</span>
                        </motion.li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RoadMap;