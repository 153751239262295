import React from 'react';
import Navbar from '../component/Navbar';
import ChainSupported from '../component/ChainSupported';
import WhyTrader from '../component/WhyTrader';
import HowItWorks from '../component/HowItWorks';
import Referring from '../component/Referring';
import Faqs from '../component/Faqs';
import JoinTapTrader from '../component/JoinTapTrader';
import RoadMap from '../component/RoadMap';
import PrivateManagementKey from '../component/PrivateManagementKey';

function Index() {
  return (
    <div>
      <Navbar />
      <ChainSupported />
      <WhyTrader />
      <PrivateManagementKey />
      <Referring />
      <HowItWorks />
      <Faqs  />
      <RoadMap />
      <JoinTapTrader />
    </div>
  );
}

export default Index;
