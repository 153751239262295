import React from 'react';
import HighSpeed from '../assets/1.svg';
import MultiChain from '../assets/3.svg';
import UserFriendly from '../assets/4.svg';

function Referring() {
  const data = [
    {
      title: 'Instant Trade Execution',
      description:
        'TapTrader ensures lightning-fast trade execution across Ethereum, Solana, and Base, allowing you to secure the best market opportunities in real-time.',
      imageUrl: UserFriendly,
    },
    {
      title: 'Advanced Sniping Tools',
      description:
        'Leverage TapTrader’s Token Sniper and Auto Sniper functionalities to capture the best entry points and maximize your profits with ease.',
      imageUrl: HighSpeed,
    },
    {
      title: 'Low Transaction Fees',
      description:
        'Enjoy some of the lowest transaction fees in the market while ensuring secure and efficient portfolio management, perfect for active traders.',
      imageUrl: MultiChain,
    },
  ];

  const data1 = [
    {
      title: 'Advanced Sniping Tools',
      description:
        'Leverage TapTrader’s Token Sniper and Auto Sniper functionalities to capture the best entry points and maximize your profits with ease.',
      imageUrl: HighSpeed,
    },
    {
      title: 'Low Transaction Fees',
      description:
        'Enjoy some of the lowest transaction fees in the market while ensuring secure and efficient portfolio management, perfect for active traders.',
      imageUrl: MultiChain,
    },
  ];

  return (
    <section className='bg-whytaptraderbg py-10 md:py-10' id="referral">
      <div className='w-[90%] md:w-[85%] lg:w-[86%] mx-auto'>
        <h2 className='text-white text-3xl md:text-3xl lg:text-[40px] font-bold leading-tight tracking-tight mb-8 text-left'>
          Earn rewards by referring
          <span className='md:block md:mt-2 md:t-4'>friends to TapTrader </span>
        </h2>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-8 mb-8'>
          <p className='text-gray-300 text-base leading-relaxed'>
            Trade faster with one-tap execution, manage your portfolio securely,
            and enjoy unparalleled results with TapTrader. Our high-speed,
            low-cost trading bot is designed to help you stay ahead of the
            curve, whether you’re a seasoned trader or just starting out.
          </p>
          <p className='text-gray-300 text-base leading-relaxed'>
            TapTrader delivers high-speed trade execution, accurate portfolio
            management, and low fees. With Token Sniper and Auto Sniper
            features, you can capture the best entry points in real-time and
            maximize your profits across Ethereum, Solana, and Base networks.
          </p>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-6 mb-8'>
          {data.map((item, index) => (
            <div
              className=' border-t border-r border-b-0 border-l-0 border-tapTraderBg rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 text-center'
              key={index}
            >
              <div className='flex justify-center mb-2'>
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  className='h-12 w-12'
                />
              </div>
              <h3 className='text-white text-xl font-semibold mb-2'>
                {item.title}
              </h3>
              <p className='text-gray-400 text-base leading-relaxed'>
                {item.description}
              </p>
            </div>
          ))}
        </div>

        <div className='w-full md:w-full lg:w-full xl:w-2/3 mx-auto'>

          <div className='grid grid-cols-1 md:grid-cols-2  lg:grid-cols-2 gap-6'>
            {data1.map((item, index) => (
              <div
                className=' border-t border-r border-b-0 border-l-0 border-tapTraderBg rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 text-center'
                key={index}
              >
                <div className='flex justify-center mb-2'>
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className='h-12 w-12'
                  />
                </div>
                <h3 className='text-white text-xl font-semibold mb-2'>
                  {item.title}
                </h3>
                <p className='text-gray-400 text-base leading-relaxed'>
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Referring;
