import React from 'react';
import { Home } from 'lucide-react';

const NotFound = () => {
  return (
    <div className='min-h-screen bg-gray-50 flex items-center justify-center p-4'>
      <div className='text-center space-y-6 max-w-lg'>
        <h1 className='text-8xl font-bold text-tapTraderBlue'>404</h1>

        <div className='flex justify-center gap-2'>
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className='w-3 h-3 bg-tapTraderBlue rounded-full animate-bounce'
              style={{ animationDelay: `${i * 0.2}s` }}
            />
          ))}
        </div>

        <div className='space-y-2'>
          <h2 className='text-2xl font-semibold text-gray-900'>
            Page Not Found
          </h2>
          <p className='text-gray-600'>
            Oops! The page you're looking for seems to have wandered off into
            the digital wilderness.
          </p>
        </div>

        <div className='flex justify-center gap-4'>
          <button
            onClick={() => window.history.back()}
            className='flex items-center gap-2 px-4 py-2 bg-tapTraderBlue text-white rounded-lg hover:bg-indigo-700 transition-colors'
          >
            <Home size={20} />
            Go Home
          </button>
        </div>

        <div className='text-sm text-gray-500 pt-6'>
          <p>Here are some helpful links:</p>
          <ul className='flex justify-center gap-4 mt-2'>
            <li>
              <a href='/contact' className='text-tapTraderBlue hover:underline'>
                Contact Support
              </a>
            </li>
            <li>
              <a href='/sitemap' className='text-tapTraderBlue hover:underline'>
                Sitemap
              </a>
            </li>
            <li>
              <a href='/help' className='text-tapTraderBlue hover:underline'>
                Help Center
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
