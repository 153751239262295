import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import mobileBg from '../assets/mobileh.webp';
import desktopBg from '../assets/webh.webp';
import Header from './Header';

const Navbar = () => {
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    const loadBackgroundImage = () => {
      const img = new Image();
      const isMobile = window.innerWidth < 768;
      const src = isMobile ? mobileBg : desktopBg;
      img.src = src;
      img.onload = () => setBackgroundImage(src);
    };

    loadBackgroundImage();
    window.addEventListener('resize', loadBackgroundImage);
    return () => window.removeEventListener('resize', loadBackgroundImage);
  }, []);

  return (
    <section
      className='bg-cover md:bg-cover bg-no-repeat bg-center h-screen relative'
      style={{
        backgroundColor: '#1a1a1a',
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <Header />
      <div className='absolute inset-0 flex items-center justify-center'>
        <div className='hero__section w-full md:w-3/4 mx-auto px-4'>
          <header className='header'>
            <h1 className='text-center font-extrabold text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-7 text-white leading-tight tracking-tight'>
              TapTrader:{' '}
              <span className='text-tapTraderBlue'>Snipe Tokens Fast </span>
              <span className='md:block md:mt-2'>on ETH, TON, SOL & BASE.</span>
            </h1>
          </header>
          <div className='paragraph w-11/12 md:w-3/4 lg:w-3/4 xl:w-2/4 mx-auto'>
            <p className='text-center text-sm md:text-lg text-white leading-relaxed'>
              Experience one-tap trade execution, accurate and secure portfolio
              management, and unparalleled results.
            </p>
          </div>
          <div className='grid grid-cols-1 w-4/5 md:w-4/5 lg:w-3/5 xl:w-2/5 mx-auto md:grid-cols-2 gap-4 mt-8'>
            <div className='flex justify-center'>
              <button className='w-full flex justify-center items-center px-7 py-3 text-black bg-[rgba(147,252,228,1)] rounded-full transition-colors duration-300 shadow-md hover:opacity-90'>
                The Taptrader Bot
                <FontAwesomeIcon
                  icon={faTelegram}
                  className='h-5 ml-2 text-black'
                />
              </button>
            </div>
            <div className='flex justify-center'>
              <button className='w-full flex justify-center items-center px-8 py-3 text-black bg-white rounded-full transition-colors duration-300 shadow-md hover:opacity-90'>
                Whitepaper
                <FontAwesomeIcon
                  icon={faTelegram}
                  className='h-5 ml-2 text-black'
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
